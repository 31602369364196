<template>
  <AppLayout>
    <template v-slot:appContent>
      <section class="users-list-wrapper">
        <div class="d-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
          <h4 class="">Technician Commission Payments/View</h4>
          <div>
            <router-link :to="can('technician-commission-payment-create') ? {name:'appTechnicianCommissionPaymentCreate'} : '#'">
              <span :title="can('technician-commission-payment-create') ? 'Create' : 'Create Forbidden'"
                    class="glow d-flex align-items-center">
                <i class='bx bx-plus bx-flip-vertical'></i>&nbsp;Create</span>
            </router-link>
          </div>
        </div>
        <div class="users-list-table">
          <div v-if="technicianDetails?.id" class="card">
            <div class="card-body row">
              <div class=" col-md-4 col-sm-12">
                <span style="font-weight: bold">Technician Name: </span>
                <span>{{ this.technicianDetails.user?.fullName }}</span>
              </div>
              <div class=" col-md-4 col-sm-12">
                <span style="font-weight: bold">Technician Email: </span>
                <span>{{ this.technicianDetails.user?.email }}</span>
              </div>
              <div class=" col-md-4 col-sm-12">
                <span style="font-weight: bold">Technician Phone Number: </span>
                <span>{{ this.technicianDetails.user?.phoneNumber }}</span>
              </div>
              <div class="col-md-4 col-sm-12">
                <span style="font-weight: bold">Employee Type: </span>
                <span>{{ this.technicianDetails?.employee?.type }}</span>
              </div>
              <div class="col-md-4 col-sm-12">
                <span style="font-weight: bold">Employee ID: </span>
                <span>{{ this.technicianDetails?.employee?.idNumber }}</span>
              </div>
              <div class="col-md-4 col-sm-12">
                <span style="font-weight: bold">Employee Designation: </span>
                <span>{{ this.technicianDetails?.employee?.designation }}</span>
              </div>
              <div class="col-md-4 col-sm-12">
                <span style="font-weight: bold">Employee Salary: </span>
                <span>
                  {{ `${this.technicianDetails?.employee?.salaryAmount}${this.technicianDetails?.employee?.salaryType === 'Hourly' ? '%' : ' AUD'} ` }}
                          ({{ this.technicianDetails?.employee?.salaryType }})
                </span>
              </div>
              <div class="col-md-4 col-sm-12">
                <span style="font-weight: bold">Order Product Commission: </span>
                <span>{{ this.technicianDetails?.productCommissionRate }}% ({{ this.technicianDetails?.productCommissionType }})</span>
              </div>
              <div class="col-md-4 col-sm-12">
                <span style="font-weight: bold">Date Range: </span>
                <span>{{ this.dateFormatter(this.technicianCommissionPayment.from_date) }} - {{ this.dateFormatter(this.technicianCommissionPayment.to_date) }}</span>
              </div>
              <hr>
              <div class="col-md-4 col-sm-12">
                <span style="font-weight: bold">Total Addition Commission: </span>
                <span>${{ (this.totalExtraCommissionPaymentsAdditionAmount / 100)?.toFixed(2) ?? 0 }}</span>
              </div>

              <div class="col-md-4 col-sm-12">
                <span style="font-weight: bold">Total Fuel  Allowance: </span>
                <span>${{ (this.totalExtraCommissionPaymentsFuelAllowance / 100)?.toFixed(2) ?? 0 }}</span>
              </div>
              <div class="col-md-4 col-sm-12">
                <span style="font-weight: bold">Total Commission Deduction: </span>
                <span>${{ (this.totalExtraCommissionPaymentsDeductionAmount / 100)?.toFixed(2) ?? 0 }}</span>
              </div>
              <hr>
              <div class="col-md-4 col-sm-12">
                <span style="font-weight: bold">Total Service Commission: </span>
                <span>${{ (this.totalTechnicianWageAmountFromAppointment / 100)?.toFixed(2) ?? 0 }}</span>
              </div>
              <div class="col-md-4 col-sm-12">
                <span style="font-weight: bold">Total Order Product Commission: </span>
                <span>${{ (this.totalOrderProductCommissionAmount / 100)?.toFixed(2) ?? 0 }}</span>
              </div>
              <div class="col-md-4 col-sm-12">
                <span style="font-weight: bold">Total Payable Commission: </span>
                <span>${{ (this.totalTechnicianCommissionPayableAmount / 100)?.toFixed(2) ?? 0 }}</span>
              </div>
              <hr>
              <div class="col-md-12 col-sm-12 text-right">
                <router-link class="btn btn-primary btn-sm"
                             :title="can('technician-commission-payment-view') ? 'Print View' : 'Print View Unauthenticated'"
                             :to="can('technician-commission-payment-view') ? {name:'appTechnicianPaymentPrint',params:{id:technicianCommissionPayment.id}} : '#'">Print Invoice
                </router-link>
              </div>

            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <h3 class="text-center text-uppercase">Job Details</h3>
              <hr>
              <div v-if="technicianAppointmentListPayments.length" class="table-responsive">
                <table id="users-list-datatable" class="table">
                  <thead>
                  <tr>
                    <th class="position-relative" :style="{width:'5%'}">SL</th>
                    <th class="position-relative" :style="{width:'25%'}">JOB DETAILS</th>
                    <th class="position-relative text-right" :style="{width:'15%'}">SERVICE PRICE</th>
                    <th class="position-relative" :style="{width:'10%'}">HOURS</th>
                    <th class="position-relative text-right" :style="{width:'15%'}">SERVICE COMMISSION</th>
                    <th class="position-relative" :style="{width:'10%'}">ORDER</th>
                    <th class="position-relative text-right" :style="{width:'10%'}">PRODUCT COMMISSION</th>
                    <th class="position-relative text-right" :style="{width:'10%'}">JOB VAlUE</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(technicianAppointmentDetails, index) in this.technicianAppointmentListPayments" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td>
                      <div>
                        {{ technicianAppointmentDetails?.reference }}
                        ({{ technicianAppointmentDetails?.customer?.userFullName }})
                      </div>
                      <div>
                        <span class="font-size-small">
                          Service: {{ technicianAppointmentDetails.serviceName }}
                        </span>
                      </div>
                      <div>
                        <span class="font-size-small">
                          {{ technicianAppointmentDetails.date }} at {{ technicianAppointmentDetails.time }}
                        </span>
                      </div>
                    </td>
                    <td class="text-right">
                      <span>${{ (technicianAppointmentDetails.unitPrice / 100).toFixed(2) }}</span>
                    </td>
                    <td>{{ technicianAppointmentDetails.length }}</td>
                    <td class="text-right">
                      ${{ (technicianAppointmentDetails.technicianWageAmountFromAppointment / 100).toFixed(2) }}
                    </td>
                    <td>
                      <span class="font-size-small">
                        {{ technicianAppointmentDetails.taggedOrder?.orderNo ?? "-" }}
                      </span><br/>
                      <span class="font-size-small">
                                {{ technicianAppointmentDetails.taggedOrder?.orderNo ? 'Status: ' : '' }}
                                {{ technicianAppointmentDetails.taggedOrder?.status }}
                      </span>
                    </td>
                    <td class="text-right">
                      <span>
                        ${{ (technicianAppointmentDetails.orderProductCommissionAmount / 100).toFixed(2) }}
                      </span>
                    </td>
                    <td class="text-right">
                      <span>
                        ${{ (technicianAppointmentDetails.totalJobValue / 100).toFixed(2) }}
                      </span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div v-else>
                <span class="text-center text-warning">{{ this.noTechnicianAppointmentListMessage }}</span>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <h3 class="text-center text-uppercase">Addon Commission Details</h3>
              <div class="col-12 text-right">
                <button class="btn btn-sm btn-warning"
                        data-toggle="modal"
                        data-target="#technicianExtraCommissionCreateModal"
                        :title="can('technician-extra-commission-create') ? 'Add' : 'Add Unauthenticated'"
                        :disabled="!can('technician-extra-commission-create')">Add Another
                </button>
              </div>
              <hr>
              <div v-if="this.technicianExtraCommissionPayments.length" class="table-responsive">
                <table id="users-list-datatable" class="table">
                  <thead>
                  <tr>
                    <th class="position-relative" :style="{width:'5%'}">SL</th>
                    <th class="position-relative" :style="{width:'15%'}">TYPE</th>
                    <th class="position-relative text-right" :style="{width:'10%'}">AMOUNT</th>
                    <th class="position-relative" :style="{width:'65%'}">NOTE</th>
                    <th class="position-relative text-right" :style="{width:'5%'}">ACTION</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(technicianExtraCommissionPayment, index) in this.technicianExtraCommissionPayments" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td>{{ technicianExtraCommissionPayment?.commission_type }}</td>
                    <td class="text-right">
                      <span>
                        ${{ (technicianExtraCommissionPayment?.amount / 100).toFixed(2) }}
                      </span>
                    </td>
                    <td>
                      {{ technicianExtraCommissionPayment?.note }}
                    </td>
                    <td>
                      <div class="text-right">

                        <div class="dropdown py-0 px-0 view-edit-delte-icon" :style="{height:'18px'}">
                          <span type="button"
                                class="px-0 py-0"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false">
                            <i class='bx bx-dots-vertical-rounded h-100'></i>
                          </span>
                          <div class="dropdown-menu">
                            <button class="dropdown-item"
                                    data-toggle="modal"
                                    data-target="#deleteTechnicianExtraCommissionModel"
                                    :title="can('technician-extra-commission-delete') ? 'Delete' : 'Delete Unauthenticated'"
                                    :disabled="!can('technician-extra-commission-delete')"
                                    @click="this.technicianExtraCommissionIdForDelete = technicianExtraCommissionPayment.id">Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </td>

                  </tr>
                  </tbody>
                </table>
              </div>
              <div v-else>
                <span class="text-center text-warning">{{ this.noTechnicianExtraCommissionListMessage }}</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <DeleteAlertForTechnicianExtraCommission modal-name="deleteTechnicianExtraCommissionModel"
                                               :model-id="technicianExtraCommissionIdForDelete"
                                               @confirmModelDeletion="confirmExtraCommissionDeletion"/>
      <TechnicianExtraCommissionCreateModal
          :technician-commission-payment-reference="technicianCommissionPayment?.reference"
          :technician-user-full-name="technicianDetails?.user?.fullName"
          :technician-commission-payment-id="technicianCommissionPayment?.id"
          :technician-id="technicianCommissionPayment?.technician?.id"
          @technicianExtraCommissionCreated="calculateTechnicianExtraCommissionRelatedDataCalculation"/>
    </template>
  </AppLayout>
</template>

<script>
import AppLayout from '@/layouts/backEnd/AppLayout'
import TechnicianExtraCommissionCreateModal from '@/views/backEnd/technicianCommissionPayments/includes/TechnicianExtraCommissionCreateModal'
import DeleteAlertForTechnicianExtraCommission from '@/components/backEnd/modal/DeleteAlertModal';

import {mapActions, mapGetters} from "vuex";

import Loader from "@/components/backEnd/mixins/Loader";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Authorization from "@/components/backEnd/mixins/Authorization";

export default {
  name: "TechnicianCommissionPaymentView",
  components: {
    AppLayout,
    TechnicianExtraCommissionCreateModal,
    DeleteAlertForTechnicianExtraCommission
  },
  mixins: [Loader, ShowToastMessage, Authorization],
  data() {
    return {
      unauthorized: 'You are unauthorized to perform this action.',
      noTechnicianAppointmentListMessage: 'Sorry, no details found!',
      noTechnicianExtraCommissionListMessage: 'No Extra Commission for the payment',
      technicianExtraCommissionIdForDelete: null,
      getTechnicianCommissionPaymentParams: {
        with_relation: [
          'technician.employee',
          'technician.user',
        ],
      },
      getTechnicianExtraCommissionsParams: {
        tech_com_pay_id: null,
        technician_id: null,
      },

      technicianDetails: null,
      technicianAppointmentListPayments: [],
      totalTechnicianWageAmountFromAppointment: 0,
      totalOrderProductCommissionAmount: 0,
      totalExtraCommissionPaymentsAdditionAmount: 0,
      totalExtraCommissionPaymentsFuelAllowance: 0,
      totalExtraCommissionPaymentsDeductionAmount: 0,
      totalTechnicianCommissionPayableAmount: 0,
    };
  },
  computed: {
    ...mapGetters({
      technicianCommissionPayment: "appTechnicianCommissionPayments/technicianCommissionPayment",
      technicianExtraCommissionPayments: "appTechnicianExtraCommissions/technicianExtraCommissions",
    })
  },
  watch: {},
  methods: {
    ...mapActions({
      commitNotFoundRouteStatus: 'commitNotFoundRouteStatus',

      getTechnicianCommissionPayment: 'appTechnicianCommissionPayments/getTechnicianCommissionPayment',
      resetTechnicianCommissionPayment: 'appTechnicianCommissionPayments/resetTechnicianCommissionPayment',

      getTechnicianExtraCommissions: 'appTechnicianExtraCommissions/getTechnicianExtraCommissions',
      restTechnicianExtraCommissions: 'appTechnicianExtraCommissions/resetTechnicianExtraCommissions',

      postTechnicianExtraCommissionOnList: 'appTechnicianExtraCommissions/postTechnicianExtraCommissionOnList',
      deleteTechnicianExtraCommissionOnList: 'appTechnicianExtraCommissions/deleteTechnicianExtraCommissionOnList'
    }),

    dateFormatter(dateString) {
      // create a new Date object using the input date string
      const date = new Date(dateString);

      // create an array of month names
      const monthNames = [
        "January", "February", "March", "April", "May", "June", "July",
        "August", "September", "October", "November", "December"
      ];

      // get the day, month, and year from the Date object
      const day = date.getDate();
      const monthIndex = date.getMonth();
      const year = date.getFullYear();

      // add a leading zero to the day if it's a single digit
      const formattedDay = day < 10 ? `0${day}` : day;

      // format the date string as "dd Month yyyy" and return
      return `${formattedDay} ${monthNames[monthIndex]} ${year}`;
    },

    async getSingleTechnicianCommissionPayment(id) {
      let paramObj = {
        id: id,
        params: this.getTechnicianCommissionPaymentParams,
      }
      await this.getTechnicianCommissionPayment(paramObj).then(async response => {
        if (response.status === 404) {
          await this.commitNotFoundRouteStatus(true);
          return;
        }

        if (response.status !== 200) {
          await this.showToastMessage(response);
          return;
        }
        let technicianCommissionPaymentDetails = JSON.parse(this.technicianCommissionPayment?.details);

        this.technicianDetails = technicianCommissionPaymentDetails?.technicianDetails;
        this.technicianAppointmentListPayments = technicianCommissionPaymentDetails?.technicianAppointmentListPayments;

        this.totalTechnicianWageAmountFromAppointment = technicianCommissionPaymentDetails?.totalTechnicianWageAmountFromAppointment;
        this.totalOrderProductCommissionAmount = technicianCommissionPaymentDetails?.totalOrderProductCommissionAmount;
      })
    },

    async getTechnicianExtraCommissionList() {
      this.getTechnicianExtraCommissionsParams.tech_com_pay_id = this.technicianCommissionPayment?.id;
      this.getTechnicianExtraCommissionsParams.technician_id = this.technicianCommissionPayment?.technician?.id;

      await this.getTechnicianExtraCommissions(this.getTechnicianExtraCommissionsParams).then(async response => {
        if (response.status !== 200) {
          this.showToastMessage(response);
        }
      });
    },

    async calculateTechnicianExtraCommissionRelatedDataCalculation() {
      this.totalExtraCommissionPaymentsAdditionAmount = await this.totalExtraCommissionPaymentsAdditionAmountCalculation() ?? 0;
      this.totalExtraCommissionPaymentsFuelAllowance = await this.totalExtraCommissionPaymentsTravelAllowanceAmountCalculation() ?? 0;
      this.totalExtraCommissionPaymentsDeductionAmount = await this.totalExtraCommissionPaymentsDeductionAmountCalculation() ?? 0;
      this.totalTechnicianCommissionPayableAmount = await this.totalPayableCommissionCalculation() ?? 0;
    },

    async totalExtraCommissionPaymentsAdditionAmountCalculation() {
      return this.technicianExtraCommissionPayments?.reduce((accumulator, currentValue) => {
        if (currentValue?.commission_type === 'Addition' || currentValue?.commission_type === 0) {
          accumulator += currentValue?.amount ?? 0;
        }
        return accumulator;
      }, 0);
    },

    async totalExtraCommissionPaymentsDeductionAmountCalculation() {
      return this.technicianExtraCommissionPayments?.reduce((accumulator, currentValue) => {
        if (currentValue?.commission_type === 'Deduction' || currentValue?.commission_type === 1) {
          accumulator += currentValue?.amount ?? 0;
        }
        return accumulator;
      }, 0);
    },

    async totalExtraCommissionPaymentsTravelAllowanceAmountCalculation() {
      return this.technicianExtraCommissionPayments?.reduce((accumulator, currentValue) => {
        if (currentValue?.commission_type === 'Travel Allowance' || currentValue?.commission_type === 2) {
          accumulator += currentValue?.amount ?? 0;
        }
        return accumulator;
      }, 0);
    },

    async totalPayableCommissionCalculation() {
      return this.totalTechnicianWageAmountFromAppointment
          + this.totalOrderProductCommissionAmount
          + this.totalExtraCommissionPaymentsAdditionAmount
          + this.totalExtraCommissionPaymentsFuelAllowance
          - this.totalExtraCommissionPaymentsDeductionAmount;
    },

    async confirmExtraCommissionDeletion(confirmModelDeletion) {
      await this.deleteSingleTechnicianExtraCommissionPaymentOnList(confirmModelDeletion.modelId);
      await this.calculateTechnicianExtraCommissionRelatedDataCalculation();
    },

    async deleteSingleTechnicianExtraCommissionPaymentOnList(id) {
      this.deleteTechnicianExtraCommissionOnList(id).then(async (response) => {
        if (response.status === 200 || response.status === 204) {
          const toastObj = {
            message: "Technician Extra Commission Payment Deleted",
            type: 'success'
          };
          await this.showToastMessage(toastObj);
          return;
        }
        await this.showToastMessage(response);
      })
    },

  },
  async mounted() {
    await this.loader(true);
    const routeParams = await this.$route.params;
    await this.getSingleTechnicianCommissionPayment(routeParams.id);
    await this.getTechnicianExtraCommissionList();
    await this.calculateTechnicianExtraCommissionRelatedDataCalculation();
    await this.loader(false);
  },
  async beforeMount() {
    await this.resetTechnicianCommissionPayment();
    await this.restTechnicianExtraCommissions();
  }
}
</script>

<style scoped>

</style>