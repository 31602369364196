<template>
  <div class="modal fade" id="technicianExtraCommissionCreateModal" tabindex="-1" role="dialog" aria-labelledby="technicianExtraCommissionCreateModal"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-xl modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <h1 class="modal-title white">Technician Extra Commission ({{ technicianCommissionPaymentReference }})</h1>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <i class="bx bx-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12 col-sm-12 col-md-3">
              <div class="form-group">
                <label>Technician Name</label>
                <input :value="technicianUserFullName" type="text" class="form-control "
                       placeholder="Technician Name"
                       readonly/>
                <div class="text-danger" v-if="errors.technicianId">{{ errors.technicianId[0] }}</div>
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-3">
              <div class="form-group">
                <label>Payment Reference</label>
                <input :value="technicianCommissionPaymentReference" type="text" class="form-control "
                       placeholder="Technician Commission Payment Reference"
                       readonly/>
                <div class="text-danger" v-if="errors.technicianCommissionPaymentId">{{ errors.technicianCommissionPaymentId[0] }}</div>
              </div>
            </div>

            <div class="col-12 col-sm-12 col-md-3">
              <label>Commission Type</label>
              <VueMultiselect v-model="selectedCommissionType" class="" :options="commissionTypeOptions" :close-on-select="true"
                              label="name" track-by="name" :show-labels="false" :allow-empty="false"/>
              <div class="text-danger" v-if="errors.commissionType">{{ errors.commissionType[0] }}</div>
            </div>
            <div class="col-12 col-sm-12 col-md-3">
              <div class="form-group">
                <label>Amount *</label>
                <input v-model="amount" type="text" class="form-control "
                       placeholder="Enter Amount">
                <div class="text-danger" v-if="errors.amount">{{ errors.amount[0] }}</div>
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-12">
              <div class="form-group">
                <div>
                  <label>Note</label>
                  <textarea v-model="note" id="textareaNote"
                            class="form-control"></textarea>
                </div>
                <div class="text-danger" v-if="errors.note">{{ errors.note[0] }}</div>
              </div>
            </div>
            <div class="col-12 text-right">
              <button type="button"
                      class="btn btn-sm btn-primary"
                      :disabled="!activeCreateButton"
                      @click="addButtonHandler">Add
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// package
import VueMultiselect from 'vue-multiselect';
// mixin
import Loader from "@/components/backEnd/mixins/Loader";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import {mapActions} from "vuex";


export default {
  name: "TechnicianExtraCommissionCreateModal",
  mixins: [Loader, ShowToastMessage],
  emits:['technicianExtraCommissionCreated'],
  props: {
    technicianCommissionPaymentReference: {
      type: String,
      required: true,
      default: null
    },
    technicianUserFullName: {
      type: String,
      required: true,
      default: null
    },
    technicianId: {
      type: [Number, String],
      required: true,
      default: null,
    },
    technicianCommissionPaymentId: {
      type: [Number, String],
      required: true,
      default: null
    },
  },
  components: {
    VueMultiselect
  },
  data() {
    return {
      selectedCommissionType: {
        value: null,
        name: 'None'
      },
      amount: 0,
      note: null,
      errors: {
        technicianId: null,
        technicianCommissionPaymentId: null,
        commissionType: null,
        amount: null,
        note: null,
      }
    };
  },
  computed: {
    commissionTypeOptions() {
      return [
        {
          value: 0,
          name: 'Addition'
        },
        {
          value: 1,
          name: 'Deduction'
        },
        {
          value: 2,
          name: 'Travel Allowance'
        }
      ]
    },
    activeCreateButton() {
      return this.technicianCommissionPaymentId !== null && this.technicianId !== null && this.amount > 0 && this.note !== null;
    },
    methods: {}
  },
  methods: {
    ...mapActions({
      postTechnicianExtraCommissionOnList: 'appTechnicianExtraCommissions/postTechnicianExtraCommissionOnList'
    }),
    async addButtonHandler() {
      await this.createTechnicianExtraCommissionOnList();
    },

    async createTechnicianExtraCommissionOnList() {
      let data = {
        tech_com_pay_id: this.technicianCommissionPaymentId,
        technician_id: this.technicianId,
        commission_type: this.selectedCommissionType.value,
        amount: this.amount * 100,
        note: this.note
      };

      await this.postTechnicianExtraCommissionOnList(data).then(async response => {
        if (response.status === 200 || response.status === 201) {
          const toastObj = {
            message: 'Record Added.',
            type: 'success',
          };
          await this.showToastMessage(toastObj);
          await this.resetErrors();
          await this.resetPostData();
          this.$emit('technicianExtraCommissionCreated', {});
          document.querySelector('[data-target="#technicianExtraCommissionCreateModal"]').click();
          return;

        }

        await this.setErrors(response?.errors);
        await this.showToastMessage(response);
      });
    },

    async setErrors(errors) {
      this.errors.technicianId = errors?.technician_id;
      this.errors.technicianCommissionPaymentId = errors?.tech_com_pay_id;
      this.errors.commissionType = errors?.commission_type;
      this.errors.amount = errors?.amount;
      this.errors.note = errors?.note;
    },

    async resetPostData() {
      this.selectedCommissionType = {value: null, name: 'None'};
      this.amount = 0;
      this.note = null;
      this.note = null;
    },
    async resetErrors() {
      this.errors = {
        technicianId: null,
        technicianCommissionPaymentId: null,
        commissionType: null,
        amount: null,
        note: null,
      }
    },

  }

}
</script>

<style scoped>

</style>